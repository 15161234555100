import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import htmlParser from 'react-html-parser';
import './TreeNameInfo.css';
import uuid from 'react-uuid';

const TreeNameInfo = (props) => {
    const btDetail = props.btData.btDetail;
    const btInfo = btDetail.btInfo;
    const btSynonyms = btDetail.btSynonyms;
    const btCommons = btDetail.btAdditionalCommons;
    const [isCommonCollapsed, setIsCommonCollapsed] = useState(true);
    const [isSynonymCollapsed, setIsSynonymCollapsed] = useState(true);
    const renderScientificName = () => {
        try {
            if (btInfo) {
                return (
                    <span>{htmlParser(btInfo.name_concat)}</span>
                )
            } else {
                throw new Error(`missing scientific name`);
            }
        } catch (err) {
            console.log(`components.TreeNameInfo.renderScientificName: ${err}`);
            return null;
        }
    }
    const toggleCommonCollapse = () => {
        setIsCommonCollapsed(prev => !prev);
    };
    const toggleSynonymCollapse = () => {
        setIsSynonymCollapsed(prev => !prev);
    };

    const renderSynonyms = () => {
        if (btSynonyms) {
            const visibleSynonyms = isSynonymCollapsed
                ? btSynonyms.slice(0, 5)
                : btSynonyms;
            return (
                <Col
                  xs={{ span: 12 }}
                  md={4}
                  lg={4}
                  className="less-line-spacing tree-name-info-middle-text"
                >
                  <h4 className="label-demibold">SYNONYMS</h4>
                  {visibleSynonyms.length
                    ? visibleSynonyms.map(s => {
                        return (
                          <p className="font-italic" key={uuid()}>
                            {htmlParser(s.name_concat)}
                          </p>
                        );
                      })
                    : ""}
                  {/* Render the toggle only if there are more than 5 items */}
                  {btSynonyms.length > 5 && (
                    <p
                      className="karat-toggle"
                      onClick={() => setIsSynonymCollapsed(!isSynonymCollapsed)}
                      style={{ cursor: "pointer", color: "#457537" }}
                    >
                      {isSynonymCollapsed ? "Show More" : "Show Less"}
                    </p>
                  )}
                </Col>
            );
        } else {
            return (
                <Col xs={{ span: 12 }} md={4} lg={4} className="less-line-spacing tree-name-info-middle-text">
                    <h4 className="label-demibold">SYNONYMS</h4>
                </Col>
            );
        }
    }

    const renderCommons = () => {
        if (btCommons) {
            const visibleCommons = isCommonCollapsed
                ? btCommons.slice(0, 5)
                : btCommons;

            return (
                <Col
                    xs={{ span: 12 }}
                    md={4}
                    lg={4}
                    className="less-line-spacing tree-name-info-right-text"
                >
                    <h4 className="label-demibold">ADDITIONAL COMMON NAMES</h4>
                    {visibleCommons.map((s, idx) => (
                        <p key={uuid()}>{s}</p>
                    ))}
                    {/* Render the toggle only if there are more than 5 items */}
                    {btCommons.length > 5 && (
                        <p
                            className="karat-toggle"
                            onClick={() => setIsCommonCollapsed(!isCommonCollapsed)}
                            style={{ cursor: "pointer", color: "#457537" }}
                        >
                            {isCommonCollapsed ? "Show More" : "Show Less"}
                        </p>
                    )}
                </Col>
            );
        } else {
            return (
                <Col
                    xs={{ span: 12 }}
                    md={4}
                    lg={4}
                    className="less-line-spacing tree-name-info-right-text"
                >
                    <h4 className="label-demibold">ADDITIONAL COMMON NAMES</h4>
                </Col>
            );
        }
    };


   function SpeciesLink() {
      return <span>
         <a 
            className="form-button-primary"
            onClick={()=> {
                  window.open(`https://selectree.calpoly.edu/tree-detail/${btInfo.tree_id}`)
               }}>See Species
         </a>
      </span>
   }

   if (btInfo) {
      return (
         <Container className="tree-name-info">
            <Row>
               <Col xs={{ span: 12 }}>
                  <Row className="tree-name-info-row">
                     <Col xs={{ span: 12 }} md={4} className="less-line-spacing tree-name-info-left-text">
                        <h2 className="label-demibold">{btInfo.common ? btInfo.common : null}</h2>
                        <p className="font-italic text-lg tree-name-info-science-name">
                           {renderScientificName()}
                        </p>
                        <h4 className="label-demibold">FAMILY <span className="tree-name-info-family font-weight-normal">{btInfo.family ? btInfo.family : null}
                        </span>
                        </h4>
                        <SpeciesLink />
                     </Col>
                     {renderSynonyms()}
                     {renderCommons()}
                  </Row>
               </Col>
            </Row>
         </Container>
      )
   } else {
      console.log(`components.TreeNameInfo: treeDetail empty`);
      return null;
   }

}

export default TreeNameInfo;
